import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Lnb,
  CurrentBox,
  CheckBox,
  Pagination,
} from "../../components/bundle_components";
import {
  useSelectBox,
  useDatePicker,
  useCheckToken,
} from "../../hooks/bundle_hooks";




export default function UserList() {
  const navigate = useNavigate();
  const { date, start_at, end_at } = useDatePicker();
  const { mb_no, postData, resData } = useCheckToken();
  const { selectedValues, selecBoxHtml } = useSelectBox({
    sort_join: ["최근 가입일 순", "오래된 가입일 순"],
  });
  const [pageData, setPageData] = useState();
  const [curPage, setCurPage] = useState(1);
  const [beforeFilter, setBeforeFilter] = useState();

  const loadUserData = async () => {
    const order =
      selectedValues.sort_join === "최근 가입일 순" ? "desc" : "asc";
    setCurPage(1);
    const data = {
      mb_no,
      start_at,
      end_at,
      cur_page: curPage,
      order,
      type: "all",
    };
    const res = await postData("member/index", data);
    if (!res || res?.code !== 200) return;
    setPageData(res.page);
    setBeforeFilter({ ...data });

  };

  const loadPageData = async (page) => {
    const res = await postData("member/index", {
      ...beforeFilter,
      cur_page: page,
    });
    setPageData(res.page);
  };

  //전체 회원 조회후 엑셀로 다운로드
  const loadMaxData = async () => {
    const order = "desc";

    const data = {
      mb_no,
      start_at,
      end_at,
      cur_page: curPage,
      order,
      type: "max",
    };
    const res = await postData("member/index", data);
    if (!res || res?.code !== 200) {
      alert("전체 데이터 조회 실패");
    }
    else {
      downloadCSV(res.data.memberResult);
    }


  };

  function downloadCSV(dataArray) {

    if (Array.isArray(dataArray)) {
      const BOM = '\uFEFF'; // UTF-8 BOM
      // CSV 문자열 생성
      const csvContent = [
        ["ID", "이름", "성별", "생년월일", "거주인원수", "이메일", "휴대폰번호", "가입일", "계정활성화여부"], // CSV 헤더
        ...dataArray.map(item => [item.mb_id, item.mb_name, item.mb_sex, item.mb_birth, item.mb_family, item.mb_email, item.mb_hp, item.mb_datetime, item.mb_open === 0 ? "O" : "X"]) // 데이터 행
      ].map(e => e.join(",")).join("\n");

      // Blob 객체 생성
      const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });

      // 파일명 생성
      const formattedDate = getCurrentFormattedDate(); // 현재 날짜를 yyyy_mm_dd 형식으로 가져옴
      const filename = `전체 회원_${formattedDate}.csv`;


      // Blob 객체를 이용해 다운로드 링크 생성
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }

  function getCurrentFormattedDate() {
    const date = new Date();
    const year = date.getFullYear(); // 현재 연도
    let month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줌
    let day = date.getDate(); // 일

    // 월과 일을 두 자릿수로 포맷팅
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}_${month}_${day}`; // 형식에 맞게 반환
  }

  useEffect(() => {
    loadUserData();
  }, []);

  const btnEvent = {
    add() {
      navigate("/UserList/add");
    },
    down() {
      loadMaxData();
    },
  };

  return (
    <>
      <Lnb lnbType="user" />
      <CurrentBox
        btns={
          [
            "down"
          ]
        }
        tit="회원리스트"
        {...btnEvent}
      />
      <div className="user_list box_ty01 table_type table_comm">
        <div className="filter_wrap d-flex">
          <div className="select_input_wrap d-flex">{selecBoxHtml}</div>
          <div className="date_input_wrap d-flex">
            <div className="date_input input_ty02">{date.start}</div>
            <div className="date_input input_ty02">{date.end}</div>
          </div>
          <button
            type="button"
            className="m-txt-4 btn_ty01 btn_search"
            onClick={loadUserData}
          >
            검색
          </button>
        </div>
        <div className="table_wrap part">
          <table className="table reTable " id="table">
            <colgroup>
              <col width={"50px"} />
              <col width={"192px"} />
              <col width={"60px"} />
              <col width={"80px"} />
              <col width={"100px"} />
              <col width={"100px"} />
              <col width={"110px"} />
              <col width={"100px"} />
              <col width={"100px"} />
              <col width={"108px"} />
              {/* <col width={"170px"} /> */}
            </colgroup>
            <thead>
              <tr>
                <th className="num">NO</th>
                <th className="id">아이디</th>
                <th className="name">이름</th>
                <th className="gender">성별</th>
                <th className="birth">생년월일</th>
                <th className="people">거주인원 수</th>
                <th className="email">이메일</th>
                <th className="phone">휴대폰 번호</th>
                <th className="joinDate">가입일</th>
                <th className="active">계정활성화여부</th>
                {/* <th className="etc">비고</th> */}
              </tr>
            </thead>
            <tbody>
              {resData?.memberResult.map((el, idx) => {
                return <UserItem key={idx} data={el} no={idx} />;
              })}
            </tbody>
          </table>
          {!resData?.memberResult[0] && (
            <div className="no_data_wrap">데이터 없음</div>
          )}
        </div>
        <CurrentBox
          btns={
            [
              /* "down" */
            ]
          }
          hideTit={true}
          {...btnEvent}
        />
        {pageData && (
          <Pagination
            pageData={pageData}
            curPage={curPage}
            setCurPage={setCurPage}
            onClick={loadPageData}
          />
        )}
      </div>
    </>
  );
}


function UserItem({ no, data }) {
  const navigate = useNavigate();
  return (
    <tr
      onClick={() => { navigate("/UserBasicInfo/" + data.mb_id) }}
      style={{ cursor: "pointer" }}
    >
      <td className="num">{no + 1}</td>
      <td className="id">{data.mb_id}</td>
      <td className="name">{data.mb_name}</td>
      <td className="gender">{data.mb_sex}</td>
      <td className="birth">{data.mb_birth}</td>
      <td className="people">{data.mb_family}</td>
      <td className="email">
        {data.mb_email.split("@")[0]}
        <br />
        {"@" + data.mb_email.split("@")[1]}
      </td>
      <td className="phone">{data.mb_hp}</td>
      <td className="joinDate">{data.mb_datetime}</td>
      <td className="active">{data.mb_open === 0 ? "O" : "X"}</td>
      {/* <td className="etc input_ty02 userlist">
        <input type="text" placeholder="직접입력" defaultValue={data.mb_2} />
      </td> */}
    </tr>
  );
}
