import { Link } from "react-router-dom";
import {
  Lnb,
  CurrentBox,
  Pagination,
} from "../../components/bundle_components";
import {
  useSelectBox,
  useCheckToken,
  useDatePicker,
} from "../../hooks/bundle_hooks";
import { useEffect, useState } from "react";

export default function CalculatorList() {
  const [pageData, setPageData] = useState();
  const [curPage, setCurPage] = useState(1);
  const { date, start_at, end_at } = useDatePicker();
  const { mb_no, postData, resData, setResData } = useCheckToken();
  const [beforeFilter, setBeforeFilter] = useState();
  const { selectedValues, selecBoxHtml } = useSelectBox({
    order: ["CO2 발생량 높은 순", "CO2 발생량 낮은 순"],
    gender: ["전체(성별)", "남성", "여성"],
    city: [
      "전체(지역)",
      "서울특별시",
      "경기도",
      "강원특별자치도",
      "경상남도",
      "경상북도",
      "전라남도",
      "전라북도",
      "충청북도",
      "충청남도",
      "제주특별자치도",
      "인천광역시",
      "대전광역시",
      "대구광역시",
      "광주광역시",
      "부산광역시",
      "울산광역시",
    ],
  });

  const loadCalcData = async () => {
    const data = {
      mb_no,
      order: {
        전체: "all",
        "CO2 발생량 높은 순": "co2_asc",
        "CO2 발생량 낮은 순": "co2_desc",
      }[selectedValues.order],
      gender: { "전체(성별)": "all", 남성: "male", 여성: "female" }[
        selectedValues.gender
      ],
      city: selectedValues.city,
      start_at,
      end_at,
    };
    const res = await postData("calculator/list", data);
    if (!res || res?.code !== 200) return;
    setBeforeFilter({ ...data });
    setPageData(res.page);
    setCurPage(1);
    if (!res.data) setResData([]);
  };

  const loadPageData = async (page) => {
    const res = await postData("calculator/list", {
      ...beforeFilter,
      cur_page: page,
    });
    setPageData(res.page);
  };

  const btnEvent = {
    down() {
      loadMaxData();
    },
  };

  //엑셀 다운로드 전체 데이터 조회
  const loadMaxData = async () => {
    const data = {
      mb_no,
      order: "co2_asc",
      gender: "all",
      city: selectedValues.city,
      start_at,
      end_at,
      list_items: 9999999
    };
    const res = await postData("calculator/list", data);
    if (!res || res?.code !== 200) {
      alert("전체 데이터 조회 실패");
    }
    else {
      downloadCSV(res.data);
    }

  };

  function downloadCSV(dataArray) {

    if (Array.isArray(dataArray)) {
      const BOM = '\uFEFF'; // UTF-8 BOM
      // CSV 문자열 생성
      const csvContent = [
        ["최근계산일", "아이디", "지역", "성별", "생년월일", "거주인원수", "총CO2 발생량(kg)", "소나무(그루)"], // CSV 헤더
        ...dataArray.map(item => [item.create_at,item.mb_id, item.addr, item.mb_sex, item.mb_birth, item.mb_family, item.total_carbon, item.needed_tree]) // 데이터 행
      ].map(e => e.join(",")).join("\n");

      // Blob 객체 생성
      const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });

      // 파일명 생성
      const formattedDate = getCurrentFormattedDate(); // 현재 날짜를 yyyy_mm_dd 형식으로 가져옴
      const filename = `탄소발자국 계산 내역_${formattedDate}.csv`;


      // Blob 객체를 이용해 다운로드 링크 생성
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  function getCurrentFormattedDate() {
    const date = new Date();
    const year = date.getFullYear(); // 현재 연도
    let month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줌
    let day = date.getDate(); // 일

    // 월과 일을 두 자릿수로 포맷팅
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}_${month}_${day}`; // 형식에 맞게 반환
  }


  useEffect(() => {
    loadCalcData();
  }, []);

  return (
    <>
      <Lnb lnbType="calcHistory" />
      <CurrentBox btns={["down"]} tit="탄소발자국 계산 내역 리스트" {...btnEvent} />
      <div className="calc_list box_ty01 table_type table_comm">
        <div className="filter_wrap d-flex">
          <div className="select_input_wrap d-flex">{selecBoxHtml}</div>
          <div className="date_input_wrap d-flex">
            <div className="date_input input_ty02">{date.start}</div>
            <div className="date_input input_ty02">{date.end}</div>
          </div>
          <button
            type="button"
            className="btn_ty01 btn_search"
            onClick={loadCalcData}
          >
            검색
          </button>
        </div>
        <div className="table_wrap">
          <table className="table" id="table">
            <colgroup>
              <col width={"80px"} />
              <col width={"100px"} />
              <col width={"150px"} />
              <col width={"150px"} />
              <col width={"100px"} />
              <col width={"100px"} />
              <col width={"100px"} />
              <col width={"150px"} />
              <col width={"150px"} />
            </colgroup>
            <thead>
              <tr>
                <th className="num">NO</th>
                <th>최근계산일</th>
                <th>아이디</th>
                <th>지역</th>
                <th>성별</th>
                <th>생년월일</th>
                <th>거주인원 수</th>
                <th>총 CO2 발생량 (kg)</th>
                <th>소나무 (그루)</th>
              </tr>
            </thead>
            <tbody>
              {resData?.length > 0 ? (
                resData?.map((el, index) => (
                  <tr key={el.idx}>
                    <td>{index + 1}</td>
                    <td>{el.create_at}</td>
                    <td>
                      <Link to={`/UserCalcHis/${el.mb_id}`}>{el.mb_id}</Link>
                    </td>
                    <td>{el.addr}</td>
                    <td>{el.mb_sex}</td>
                    <td>{el.mb_birth}</td>
                    <td>{el.mb_certify}</td>
                    <td>{el.total_carbon.toLocaleString("ko-KR") || 0}</td>
                    <td>{el.needed_tree.toLocaleString("ko-KR") || 0}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">데이터 없음</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* {!resData && <div className="no_data_wrap">데이터 없음</div>} */}
        </div>
        <CurrentBox btns={[/* "down" */]} hideTit={true} />
        {pageData && (
          <Pagination
            pageData={pageData}
            curPage={curPage}
            setCurPage={setCurPage}
            onClick={loadPageData}
          />
        )}
      </div>
    </>
  );
}
